import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import zip from 'lodash/zip'

import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Youtube from '../components/shared/Youtube'
import Legal from '../components/shared/Legal'

export default class ProteoHaulierEnterprise extends React.Component {
  constructor(props) {
    super(props)
    this.state = { input: '' }
  }
  render() {
    const {
      aboutTitle,
      aboutDescription,
      benefits,
      benefitsTitle,
      benefitsImage,
      title,
      featuresTitle,
      youtubeVideoId,
    } = this.props.data.page
    const features = zip(
      this.props.data.features.edges[0].node.set,
      this.props.data.features.edges[2].node.set
    )
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-dark text-white">
          {' '}
          <Container className="py-3 ">
            <h2 className="text-gold text-center py-4 mb-4">{title}</h2>

            <div className="w-75 mx-auto">
              <Youtube videoID={youtubeVideoId} />
            </div>
            {/* FORM */}
            <Row className="my-5 justify-content-center">
              <Col sm="6" md="4" lg="3">
                <input
                  type="email"
                  placeholder="Enter your email"
                  style={{ height: '60px' }}
                  onChange={e => {
                    this.setState({ input: e.target.value })
                  }}
                  value={this.state.input}
                  className=" w-100 d-flex text-center align-middle white-btn-gradient"
                />
                <Legal dark className="text-center" />
              </Col>

              <Col sm="6" md="4" lg="3" className="text-center">
                <Button
                  color="primary"
                  style={{ height: '60px', lineHeight: 1 }}
                  size="lg"
                  tag={Link}
                  to="/contact"
                  state={{
                    selected: 'Contact Us',
                    email: this.state.input,
                  }}
                  className="w-100 text-white py-3  text-center primary-btn-gradient "
                >
                  {' '}
                  Get Started
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row className="py-5">
              <Col
                md="12"
                className="d-flex  flex-column justify-content-center"
              >
                <div
                  style={{ fontSize: '1.8em' }}
                  className="text-primary text-center mb-4"
                  dangerouslySetInnerHTML={{ __html: aboutTitle }}
                />

                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: aboutDescription.g.html }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-dark text-white py-5">
          <Container>
            <h2 className="text-gold text-center pb-4">{benefitsTitle}</h2>
            <Row className="align-items-center">
              <Col
                xs={{ order: 2 }}
                className="text-justify"
                lg={{ size: 6, order: 1 }}
                dangerouslySetInnerHTML={{ __html: benefits.g.html }}
              />
              <Col xs={{ order: 1 }} lg={{ size: 5, offset: 1, order: 2 }}>
                <img
                  className="d-block border-40 w-100 mb-3 mb-lg-0"
                  src={benefitsImage.file.url}
                  alt={benefitsImage.description}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div id="features">
          <Container className="py-5">
            <h2 className="text-primary text-center pb-4">{featuresTitle}</h2>
            <Row className="py-4">
              {features.map((feature, i) => (
                <Col
                  lg={{ size: 4, offset: i % 2 === 1 ? 2 : 1 }}
                  md="6"
                  style={{ cursor: 'pointer' }}
                  className="mb-4"
                  onClick={() => navigate(feature[1].title)}
                  key={i}
                >
                  <Row className="align-items-start justify-content-start">
                    <Col className="col-auto">
                      <img
                        src={feature[0].file.url}
                        style={{ maxHeight: '28px' }}
                        alt={feature.description}
                      />
                    </Col>
                    <Col>
                      <h4
                        style={{ textDecoration: 'none' }}
                        className="text-primary"
                      >
                        {feature[0].title}
                      </h4>
                      <p
                        className="text-justify"
                        style={{ textDecoration: 'none' }}
                      >
                        {feature[0].description}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulProteoHaulierEnterprisePage(
      id: { eq: "b3ce256b-f368-50f3-9377-df94bc584603" }
    ) {
      title: metaTitle
      description
      keywords
    }
    page: contentfulProteoHaulierEnterprisePage(
      id: { eq: "b3ce256b-f368-50f3-9377-df94bc584603" }
    ) {
      id
      metaTitle
      description
      keywords
      title
      youtubeVideoId
      aboutTitle
      aboutDescription {
        g: childMarkdownRemark {
          html
        }
      }
      benefitsTitle
      benefits {
        g: childMarkdownRemark {
          html
        }
      }
      benefitsImage {
        title
        description
        file {
          url
        }
      }
      featuresTitle
    }
    features: allContentfulProteoHaulierEnterprisePage {
      edges {
        node {
          node_locale
          set: features {
            id
            title
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`
